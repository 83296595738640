<template>
    <el-main>
        <el-card>
            <div class="dataBox">
                <div class="dataItemBox">
                    <div class="dataTitle">今日付款订单(笔)</div>
                    <div class="dataTodayNum">{{ yesterday_today_data.today_pay_order }}</div>
                    <div class="dataYesterdayNum">昨日<b>{{ yesterday_today_data.yesterday_pay_order }}</b>笔
                        <span>
                            <i v-if="yesterday_today_data.yesterday_pay_order && yesterday_today_data.today_pay_order != yesterday_today_data.yesterday_pay_order"
                                :style="{ color: yesterday_today_data.today_pay_order > yesterday_today_data.yesterday_pay_order ? '#90cf60' : '#ff7680' }"
                                :class="yesterday_today_data.today_pay_order > yesterday_today_data.yesterday_pay_order ? 'el-icon-top' : 'el-icon-bottom'"></i>
                            {{
                                dataPercentage(yesterday_today_data.today_pay_order, yesterday_today_data.yesterday_pay_order)
                            }}</span>
                    </div>
                </div>
                <div class="dataItemBox">
                    <div class="dataTitle">今日预估收益(元)</div>
                    <div class="dataTodayNum">{{ yesterday_today_data.today_pay_price }}</div>
                    <div class="dataYesterdayNum">昨日<b>{{ yesterday_today_data.yesterday_pay_price }}</b>元<span>
                            <i v-if="yesterday_today_data.yesterday_pay_price && yesterday_today_data.today_pay_price != yesterday_today_data.yesterday_pay_price"
                                :style="{ color: yesterday_today_data.today_pay_price > yesterday_today_data.yesterday_pay_price ? '#90cf60' : '#ff7680' }"
                                :class="yesterday_today_data.today_pay_price > yesterday_today_data.yesterday_pay_price ? 'el-icon-top' : 'el-icon-bottom'"></i>
                            {{ dataPercentage(yesterday_today_data.today_pay_price,
                                yesterday_today_data.yesterday_pay_price) }}</span></div>
                </div>
                <div class="dataItemBox">
                    <div class="dataTitle">本月订单数(笔)</div>
                    <div class="dataTodayNum">{{ yesterday_today_data.month_pay_order }}</div>
                    <div class="dataYesterdayNum">上月<b>{{ yesterday_today_data.lastmonth_pay_order }}</b>笔<span>
                            <i v-if="yesterday_today_data.lastmonth_pay_order && yesterday_today_data.month_pay_order != yesterday_today_data.lastmonth_pay_order"
                                :style="{ color: yesterday_today_data.month_pay_order > yesterday_today_data.lastmonth_pay_order ? '#90cf60' : '#ff7680' }"
                                :class="yesterday_today_data.month_pay_order > yesterday_today_data.lastmonth_pay_order ? 'el-icon-top' : 'el-icon-bottom'"></i>
                            {{
                                dataPercentage(yesterday_today_data.month_pay_order, yesterday_today_data.lastmonth_pay_order)
                            }}</span></div>
                </div>
                <div class="dataItemBox">
                    <div class="dataTitle">本月预估收益(元)</div>
                    <div class="dataTodayNum">{{ yesterday_today_data.month_pay_price }}</div>
                    <div class="dataYesterdayNum">上月<b>{{ yesterday_today_data.lastmonth_pay_price }}</b>元<span>
                            <i v-if="yesterday_today_data.lastmonth_pay_price && yesterday_today_data.month_pay_price != yesterday_today_data.lastmonth_pay_price"
                                :style="{ color: yesterday_today_data.month_pay_price > yesterday_today_data.lastmonth_pay_price ? '#90cf60' : '#ff7680' }"
                                :class="yesterday_today_data.month_pay_price > yesterday_today_data.lastmonth_pay_price ? 'el-icon-top' : 'el-icon-bottom'"></i>
                            {{
                                dataPercentage(yesterday_today_data.month_pay_price, yesterday_today_data.lastmonth_pay_price)
                            }}</span></div>
                </div>
            </div>
        </el-card>
        <div class="pageMidArea">
            <el-card class="leftCard">
                <div class="cardTool">
                    <div class="title">推广排行</div>
                    <div class="tools">
                        <div class="searchItem">
                            <div class="s_name">用户身份</div>
                            <el-select v-model="midLeftSearch.identity_type" @change="() => getMidLeft()" placeholder="请选择"
                                size="small">
                                <el-option label="服务商" :value="1"></el-option>
                                <el-option label="发展商" :value="2"></el-option>
                                <el-option label="商家" :value="3"></el-option>
                            </el-select>
                        </div>
                        <div class="searchItem">
                            <div class="s_name">时间段</div>
                            <el-select v-model="midLeftSearch.time_status" @change="() => getMidLeft()" placeholder="请选择"
                                size="small">
                                <el-option label="全部" :value="0"></el-option>
                                <el-option label="近30天" :value="1"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <el-table :data="midLeftSearch.list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
                    <template slot="empty">
                        <No />
                    </template>
                    <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="mobile" label="电话" align="center"></el-table-column>
                    <el-table-column prop="num" align="center">
                        <template #header>
                            <div class="header" @click="getMidLeft('num')">
                                <div class="label">订单量</div>
                                <div class="px">
                                    <i :style="{ color: midLeftSearch.order == 'num' && midLeftSearch.px == 'asc' ? '#5cb6ff' : '' }"
                                        class="el-icon-caret-top"></i>
                                    <i class="el-icon-caret-bottom"
                                        :style="{ color: midLeftSearch.order == 'num' && midLeftSearch.px == 'desc' ? '#5cb6ff' : '' }"></i>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template #header>
                            <div class="header" @click="getMidLeft('pay_price')">
                                <div class="label">成交金额</div>
                                <div class="px">
                                    <i class="el-icon-caret-top"
                                        :style="{ color: midLeftSearch.order == 'pay_price' && midLeftSearch.px == 'asc' ? '#5cb6ff' : '' }"></i>
                                    <i class="el-icon-caret-bottom"
                                        :style="{ color: midLeftSearch.order == 'pay_price' && midLeftSearch.px == 'desc' ? '#5cb6ff' : '' }"></i>
                                </div>
                            </div>
                        </template>
                        <template v-slot="{ row }">￥{{ parseFloat(row.pay_price).toFixed(2) }}</template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template #header>
                            <div class="header" @click="getMidLeft('fee')">
                                <div class="label">佣金金额</div>
                                <div class="px">
                                    <i class="el-icon-caret-top"
                                        :style="{ color: midLeftSearch.order == 'fee' && midLeftSearch.px == 'asc' ? '#5cb6ff' : '' }"></i>
                                    <i class="el-icon-caret-bottom"
                                        :style="{ color: midLeftSearch.order == 'fee' && midLeftSearch.px == 'desc' ? '#5cb6ff' : '' }"></i>
                                </div>
                            </div>
                        </template>
                        <template v-slot="{ row }">￥{{ parseFloat(row.fee).toFixed(2) }}</template>
                    </el-table-column>
                </el-table>
                <Paging :total="midLeftSearch.total" :page="midLeftSearch.page" :pageNum="midLeftSearch.rows"
                    @updatePageNum="midLeftSearchUpdate"></Paging>
            </el-card>
            <el-card class="rightCard">
                <div class="cardTool">
                    <div class="title">推广排行</div>
                    <div class="tools">
                        <el-button @click="changeMidRight(0)" :type="midRightSearch.time_status ? '' : 'primary'"
                            size="small" round>全部</el-button>
                        <el-button @click="changeMidRight(1)" :type="midRightSearch.time_status ? 'primary' : ''"
                            size="small" round>近30天</el-button>
                    </div>
                </div>
                <el-table :data="midRightSearch.list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
                    <template slot="empty">
                        <No />
                    </template>
                    <el-table-column prop="act_name" label="活动名称" align="center"></el-table-column>
                    <el-table-column prop="num" align="center">
                        <template #header>
                            <div class="header" @click="getMidRight('num')">
                                <div class="label">订单量</div>
                                <div class="px">
                                    <i :style="{ color: midRightSearch.order == 'num' && midRightSearch.px == 'asc' ? '#5cb6ff' : '' }"
                                        class="el-icon-caret-top"></i>
                                    <i class="el-icon-caret-bottom"
                                        :style="{ color: midRightSearch.order == 'num' && midRightSearch.px == 'desc' ? '#5cb6ff' : '' }"></i>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template #header>
                            <div class="header" @click="getMidRight('pay_price')">
                                <div class="label">成交金额</div>
                                <div class="px">
                                    <i class="el-icon-caret-top"
                                        :style="{ color: midRightSearch.order == 'pay_price' && midRightSearch.px == 'asc' ? '#5cb6ff' : '' }"></i>
                                    <i class="el-icon-caret-bottom"
                                        :style="{ color: midRightSearch.order == 'pay_price' && midRightSearch.px == 'desc' ? '#5cb6ff' : '' }"></i>
                                </div>
                            </div>
                        </template>
                        <template v-slot="{ row }">￥{{ parseFloat(row.pay_price).toFixed(2) }}</template>
                    </el-table-column>
                </el-table>
                <Paging :total="midRightSearch.total" :page="midRightSearch.page" :pageNum="midRightSearch.rows"
                    @updatePageNum="midRightSearchUpdate"></Paging>
            </el-card>
        </div>
        <el-card class="pageBottom">
            <div>
                <el-table :data="incomeInfo.list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
                    <template slot="empty">
                        <No />
                    </template>
                    <el-table-column prop="time" label="日期" align="center"></el-table-column>
                    <el-table-column prop="mobile" label="有效订单(笔)/较前一日" align="center">
                        <template v-slot="{ row }">{{ row.num }}/<span
                                :style="{ color: parseFloat(row.compare_num) > 0 ? '#22cf67' : '#ff7526' }">{{
                                    parseFloat(row.compare_num)
                                    > 0 ? '+' : '' }}{{
        parseFloat(row.compare_num)
    }}</span></template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="预估收益(元)/较前一日" align="center">
                        <template v-slot="{ row }">{{ parseFloat(row.fee).toFixed(2) }}/<span
                                :style="{ color: parseFloat(row.compare_fee) > 0 ? '#22cf67' : '#ff7526' }">{{
                                    parseFloat(row.compare_fee)
                                    > 0 ? '+' : '' }}{{
        parseFloat(row.compare_fee).toFixed(2)
    }}</span></template></el-table-column>
                </el-table>
                <Paging :total="incomeInfo.total" :page="incomeInfo.page" :pageNum="incomeInfo.rows"
                    @updatePageNum="incomeInfoUpdate"></Paging>
            </div>
            <div class="charts">
                <div id="OrderProportion"></div>
            </div>
        </el-card>
    </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate.js'
import { dealData } from '@/util/echarts.js'
import * as echarts from 'echarts';
import Paging from '@/components/paging';
import { type } from 'os';
export default {
    components: {
        Paging
    },
    data () {
        return {
            yesterday_today_data: {
                today_pay_order: 0,
                yesterday_pay_order: 0,
                today_pay_price: 0,
                yesterday_pay_price: 0,
                month_pay_order: 0,
                lastmonth_pay_order: 0,
                month_pay_price: 0,
                lastmonth_pay_price: 0,
            },
            midLeftSearch: {
                page: 1,
                rows: 10,
                identity_type: 1,
                time_status: 0,
                order: '',
                px: '',
                list: [],
                total: 0
            },
            midRightSearch: {
                page: 1,
                rows: 10,
                time_status: 0,
                order: '',
                px: '',
                list: [],
                total: 0
            },
            incomeInfo: {
                page: 1,
                rows: 10,
                list: [],
                total: 0
            },
            order_type: {}

        }
    },
    created () {
        this.getDataInfo()
        this.getMidLeft()
        this.getMidRight()
        this.getIncomeInfo()
    },
    methods: {
        dataPercentage (today, yesterday) {
            if (!yesterday) return '--'
            let poor = Math.abs(yesterday - today)
            return `${(poor / yesterday * 100).toFixed(2)}%`
        },
        incomeInfoUpdate (val, status) {
            if (status == 0) {
                this.incomeInfo.rows = val;
            } else {
                this.incomeInfo.page = val;
            }
            this.getIncomeInfo();
        },
        midLeftSearchUpdate (val, status) {
            if (status == 0) {
                this.midLeftSearch.rows = val;
            } else {
                this.midLeftSearch.page = val;
            }
            this.getMidLeft();
        },
        midRightSearchUpdate (val, status) {
            if (status == 0) {
                this.midRightSearch.rows = val;
            } else {
                this.midRightSearch.page = val;
            }
            this.getMidRight();
        },
        // 获取数据
        getDataInfo () {
            this.$axios.post(this.$api.serviceProvider.thirdDataList)
                .then(res => {
                    if (res.code == 0) {
                        this.yesterday_today_data = res.result
                        for (const key in this.yesterday_today_data) {
                            if (key !== 'order_type') this.yesterday_today_data[key] = Number(parseFloat(this.yesterday_today_data[key]).toFixed(2))
                        }
                        this.order_type = res.result.order_type
                        this.order_type.map(item => {
                            item.value = item.percentage
                            item.name = item.act_name
                        })
                        this.$nextTick(() => {
                            let options = dealData(this.order_type)
                            let chartDom = document.getElementById('OrderProportion');
                            let myChart = echarts.init(chartDom);
                            myChart.setOption(options);
                        })
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        dealTime () {
            let obj = {}
            let end_time = new Date().getTime() / 1000
            obj.end_time = getDateformat(end_time)
            let start_time = new Date((end_time - 60 * 60 * 24 * 30) * 1000)
            start_time.setHours(0)
            start_time.setMinutes(0)
            start_time.setSeconds(0)
            obj.start_time = getDateformat(start_time / 1000)
            return obj
        },
        getMidLeft (order) {
            if (order) this.midLeftSearch.page = 1
            let obj = {
                page: this.midLeftSearch.page,
                rows: this.midLeftSearch.rows,
            }
            if (this.midLeftSearch.identity_type) obj.identity_type = this.midLeftSearch.identity_type
            if (this.midLeftSearch.time_status) {
                let time = this.dealTime()
                obj.start_time = time.start_time
                obj.end_time = time.end_time
            }
            if (this.midLeftSearch.order) {
                obj.order = this.midLeftSearch.order
                obj.px = this.midLeftSearch.px
            }
            if (order) {
                if (obj.order !== order) {
                    this.midLeftSearch.order = order
                    this.midLeftSearch.px = 'desc'
                    obj.order = order
                    obj.px = 'desc'
                } else {
                    this.midLeftSearch.px = this.midLeftSearch.px === 'desc' ? 'asc' : 'desc'
                    obj.px = this.midLeftSearch.px
                }
            }
            this.$axios.post(this.$api.serviceProvider.getMainList, obj).then(res => {
                if (res.code == 0) {
                    this.midLeftSearch.list = res.result.list
                    this.midLeftSearch.total = res.result.total_number
                }
            })
        },
        getMidRight (order) {
            if (order) this.midRightSearch.page = 1
            let obj = {
                page: this.midRightSearch.page,
                rows: this.midRightSearch.rows,
            }
            if (this.midRightSearch.time_status) {
                let time = this.dealTime()
                obj.start_time = time.start_time
                obj.end_time = time.end_time
            }
            if (this.midRightSearch.order) {
                obj.order = this.midRightSearch.order
                obj.px = this.midRightSearch.px
            }
            if (order) {
                if (obj.order !== order) {
                    this.midRightSearch.order = order
                    this.midRightSearch.px = 'desc'
                    obj.order = order
                    obj.px = 'desc'
                } else {
                    this.midRightSearch.px = this.midRightSearch.px === 'desc' ? 'asc' : 'desc'
                    obj.px = this.midRightSearch.px
                }
            }
            this.$axios.post(this.$api.serviceProvider.getRanking, obj).then(res => {
                if (res.code == 0) {
                    this.midRightSearch.list = res.result.list
                    this.midRightSearch.total = res.result.total_number
                }
            })
        },
        getIncomeInfo () {
            this.$axios.post(this.$api.serviceProvider.getDayDateList, {
                page: this.incomeInfo.page,
                rows: this.incomeInfo.rows
            }).then(res => {
                if (res.code === 0) {
                    this.incomeInfo.list = res.result.list
                    this.incomeInfo.total = Number(res.result.total_number)
                }
            })
        },
        changeMidRight (style) {
            this.midRightSearch.time_status = style
            this.getMidRight()
        }
    }
}
</script>


<style lang="less" scoped>
.el-main {
    background-color: #fff;

    .dataBox {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .dataItemBox {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .dataTitle {
            font-size: 14px;
        }

        .dataTodayNum {
            font-size: 20px;
            margin: 15px 0;
        }

        .dataYesterdayNum {
            font-size: 12px;
        }
    }

    .pageMidArea {
        margin-top: 20px;
        display: flex;
        align-items: stretch;

        .el-card {

            .cardTool {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                    font-weight: bold;
                }

                .tools {
                    display: flex;
                    align-items: center;
                }
            }

            .el-table {
                margin-top: 16px;

                /deep/ .header {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .px {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        i {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .leftCard {
            flex: 1;
        }

        .rightCard {
            flex: 0 0 550px;
            margin-left: 20px;
        }
    }
}

.searchItem {
    display: flex;
    align-items: center;

    .s_name {
        margin: 0 10px;
    }
}

.pageBottom {
    margin-top: 20px;

    /deep/ .el-card__body {
        display: flex;
        align-items: stretch;

        &>div {
            flex: 1;
        }

        .charts {
            padding: 0px 20px 0 20px;

            #OrderProportion {
                width: 100%;
                height: 100%;
            }
        }

    }
}
</style>
