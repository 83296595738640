let option = {
  title: {
    text: '',
    left: 'center',
  },
  tooltip: {
    trigger: 'item',
    formatter: '{b}: {d}%',
  },
  //  legend: {
  //    orient: 'vertical',
  //    left: 'right',
  //    itemGap: 5,
  //  },
  series: [
    {
      name: '详情',
      type: 'pie',
      radius: '50%',
      data: [],
      center: ['50%', '50%'],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
};

export const dealData = data => {
  option.title.text = '订单类型数量占比';
  option.series[0].data = data;
  return option;
};
